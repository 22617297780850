<template>
  <div>
    <ViewTemplateSelectOption title="Selecione a opção para cadastro" :panel="panel" :options="options"
      :clickedNext="navegateTo" />
  </div>
</template>

<script>
import ViewTemplateSelectOption from "@nixweb/nixloc-ui/src/component/template/ViewTemplateSelectOption";

export default {
  name: "ProductAccessoryServiceSelectOptionView",
  components: {
    ViewTemplateSelectOption,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Estoque",
        title: "Produto",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
      options: [
        {
          title: "Equipamento",
          titleSize: "15",
          icon: "fas fa-box",
          iconSize: 35,
          value: 1,
        },
        {
          title: "Acessório",
          titleSize: "15",
          icon: "fas fa-toolbox",
          iconSize: 35,
          value: 2,
        },
        {
          title: "Kit",
          titleSize: "15",
          icon: "fa-solid fa-boxes-stacked",
          iconSize: 35,
          value: 6,
        },
        {
          title: "Material",
          titleSize: "15",
          icon: "fa-solid fa-recycle",
          iconSize: 35,
          value: 5,
        },
        {
          title: "Serviço",
          titleSize: "15",
          icon: "fas fa-people-carry",
          iconSize: 35,
          value: 3,
        },
      ],
    };
  },
  methods: {
    navegateTo(selectedOption) {
      if (selectedOption == 6) {
        this.$router.push({
          name: "kitRentCreate",
        });
      } else {
        this.$router.push({
          name: "productCreate",
          params: { type: selectedOption },
        });
      }
    },
  },
};
</script>
